<template>
  <div
    v-if="!isMobile"
    size="A4"
    class="page-a4 container elevation-5">
    <v-row class="head-a4">
      <v-col cols="auto">
        <div class="head-title">
          <slot name="title" />
        </div>
        <div class="head-note-detail">
          <p>
            เลขที่ : {{ noteId || '-' }}
          </p>
          <p>
            สร้างโดย : {{ createBy || '-' }}
          </p>
          <p>
            อัพเดทล่าสุด : {{ updateBy || '-' }}
          </p>
          <p v-if="isApproved">
            อนุมัติโดย : {{ updateBy }}
          </p>
          <p v-if="isApproved">
            วันที่อนุมัติ : {{ updateAt }}
          </p>
          <p v-if="purchaseOrderId">
            เลขที่ใบสั่งซื้อ : {{ purchaseOrderId || '-' }}
          </p>
          <p v-if="assemblyOrderId">
            เลขที่ใบสั่งผลิต : {{ assemblyOrderId || '-' }}
          </p>
          <div class="d-flex align-center">
            เหตุผล :
            <v-select
              v-if="isDraft"
              class="warehouse-selector ml-4"
              :value="reason"
              :items="getReason"
              item-text="text"
              item-value="value"
              :menu-props="{ offsetY: true }"
              placeholder="เลือกเหตุผล"
              hide-details
              dense
              :disabled="['PURCHASE_ORDER', 'ASSEMBLY_ORDER'].includes(reason)"
              @input="updateReason($event)" />
            <span
              v-else
              class="ml-1">
              {{ reason }}
            </span>
          </div>
          <div
            v-if="reason === 'STOCK_COUNT' && noteType === 'deliveryNote'"
            class="d-flex align-center">
            เอกสาร Ref. :
            <v-text-field
              v-if="isDraft"
              placeholder="เลขที่เอกสารจาก BC"
              :value="bcRef"
              required
              class="ml-4"
              dense
              @input="updateBCRef($event)" />
            <span
              v-else
              class="ml-1">
              {{ bcRef }}
            </span>
          </div>
        </div>
      </v-col>
      <v-spacer />
      <v-col
        v-if="isPending || isApproved"
        cols="auto">
        <div class="text-right">
          <h2>
            สถานะ: <span
              class="text-uppercase"
              :class="getStatusColor(status)">{{ status }}</span>
          </h2>
          <p class="mb-0">
            จำนวนสินค้า
          </p>
          <h3>
            <span>{{ count }}</span>
            /
            <span>{{ getNumber('amount') }}</span>
          </h3>
        </div>
        <div class="d-flex justify-end">
          <img
            v-if="qr"
            width="120"
            :src="qr"
            alt="qr">
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <div class="d-flex align-center warehouse-section">
          คลังสินค้า : {{ warehouse.name }}

          <v-icon
            v-if="noteType === 'withdrawNote' && haveDestination"
            color="black"
            small
            class="mx-2">
            mdi-arrow-right
          </v-icon>

          <v-icon
            v-else-if="refWarehouse && noteType === 'deliveryNote'"
            color="black"
            small
            class="mx-2">
            mdi-arrow-left
          </v-icon>

          <WarehouseSelect
            v-if="isDraft && noteType === 'withdrawNote' && haveDestination"
            v-model="computedTransferWarehouseId"
            :items="warehouses"
            item-text="name"
            item-value="id"
            :rules="textBoxRules"
            :menu-props="{ offsetY: true }"
            label=""
            placeholder="เลือกสาขา" />

          <span v-else-if="refWarehouse && refWarehouse.name">
            {{ refWarehouse.name }}
            ( <a @click="goToRef()">{{ noteRef.code }} </a> <span
              class="text-uppercase text-bold"
              :class="getStatusColor(noteRef.status)">{{ noteRef.status }}</span> )
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="note-item-list">
      <v-col
        :cols="12"
        class="head-item-list">
        <h4>รายการสินค้า</h4>
      </v-col>
    </v-row>
    <v-row
      v-if="products.length === 0"
      class="note-item-blank"
      justify="center"
      align="center">
      <v-col
        cols="auto"
        class="col-icon">
        <v-icon
          x-large
          class="icon-xxx-large">
          mdi-clipboard-list-outline
        </v-icon>
      </v-col>
      <v-col :cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      v-for="(product, productIndex) in products"
      :key="product.id"
      class="note-item page-break">
      <v-col
        :cols="2"
        class="note-item-image">
        <gw-product-image
          :src="product.photoUrl"
          custom-svg="auto"
          img />
      </v-col>
      <v-col
        :cols="10"
        class="pl-0">
        <div>{{ product.name }}</div>
        <div class="product-model">
          {{ product.model }}
        </div>
        <v-btn
          v-if="isDraft"
          class="buttun-remove-product"
          color="error"
          small
          @click="removeProduct(productIndex)">
          <gw-icon-hover
            class="remove-img"
            icon-name="mdi-delete"
            icon-hover="mdi-delete-empty"
            color="#fff"
            small />
        </v-btn>
        <v-simple-table
          class="sku-list"
          dense>
          <thead>
            <tr>
              <th
                v-for="(head, headIndex) in headTable"
                :key="`head-${headIndex}`"
                :class="{ 'text-right': head === 'Received' }"
                class="capitalize">
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sku, skuIndex) in product.skus"
              :key="`sku-${skuIndex}`"
              :class="{ 'tr-error': checkTrError(sku) }">
              <td>
                <span>
                  {{ sku.code || '-' }}
                </span>
                <span v-if="sku.sequence">
                  ({{ sku.sequence }})
                </span>
              </td>
              <td
                v-if="isDraft && reason !== 'UNDO_RECEIVED'"
                class="text-right">
                <span>
                  {{ sku.receivedQty | showNumberFormat() }} / {{ sku.poQty | showNumberFormat() }}
                </span>
              </td>
              <td>
                <span>
                  {{ sku.color || '-' }} - {{ sku.size || '-' }}
                </span>
              </td>
              <td
                v-if="isStock"
                class="text-left">
                <span>
                  {{ mapStock(sku.id, sku.amount) }}
                </span>
              </td>
              <td
                v-if="isDraft && reason !== 'UNDO_RECEIVED'"
                width="100">
                <v-text-field
                  v-model.number="sku.amount"
                  :rules="[...inputRules(sku.id, sku.amount), isOverAmount(sku)]"
                  class="note-count-input"
                  type="number"
                  outlined
                  solo
                  dense
                  :min="0"
                  @keypress="$keypressNumberNoDecimal($event)" />
              </td>
              <td
                v-else
                class="text-center"
                width="75">
                <span>{{ sku.amount }}</span>
              </td>
              <td
                v-if="isPending && reason !== 'UNDO_RECEIVED' && noteType === 'deliveryNote'"
                class="text-center"
                width="75">
                <span>{{ sku.maxAmount }}</span>
              </td>
              <td
                v-if="isDraft"
                class="text-right"
                width="75">
                <v-btn
                  class="buttun-remove-sku"
                  color="grey lighten-4"
                  icon
                  x-small
                  @click="removeSku(productIndex, skuIndex)">
                  <gw-icon-hover
                    class="remove-img"
                    icon-name="mdi-delete"
                    icon-hover="mdi-delete-empty"
                    x-small />
                </v-btn>
              </td>
              <td
                v-else-if="isPending && reason !== 'UNDO_RECEIVED'"
                width="120">
                <v-text-field
                  v-model.number="sku.count"
                  class="note-count-input"
                  type="number"
                  :rules="[...inputRules(sku.id, sku.amount), isOverCount(sku)]"
                  outlined
                  solo
                  dense
                  :min="0"
                  @keypress="$keypressNumberNoDecimal($event)" />
              </td>
              <td
                v-else
                class="text-center"
                width="65">
                <span>
                  {{ sku.count }}
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col :cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="note-text page-break">
      <v-spacer />
      <v-col :cols="5">
        <p class="caption mb-1">
          Note
        </p>
        <v-textarea
          :value="note"
          :disabled="!isDraft"
          :height="150"
          hide-details
          outlined
          solo
          dense
          @input="updateNote($event)" />
      </v-col>
    </v-row>
    <v-row
      v-if="isApproved"
      class="row-sign-name mt-15">
      <v-col :cols="4">
        <div class="sign-name">
          <p class="sign-name-title">
            ผู้นำสินค้าเข้า
          </p>
          <p>ลงชื่อ .....................................................</p>
          <p>
            <span class="sign-bracket">
              ( .................................................. )
            </span>
          </p>
          <p>วันที่ ............./............./.............</p>
        </div>
      </v-col>
      <v-col :cols="4" />
      <v-col :cols="4">
        <div class="sign-name">
          <p class="sign-name-title">
            ผู้ดูแลสต็อกสินค้า
          </p>
          <p>ลงชื่อ .....................................................</p>
          <p>
            <span class="sign-bracket">
              ( .................................................. )
            </span>
          </p>
          <p>วันที่ ............./............./.............</p>
          <p> พิมพ์ {{ printAt }}</p>
        </div>
      </v-col>
    </v-row>
  </div>
  <div
    v-else
    size="MOBILE">
    <v-row class="py-2 mb-4">
      <v-col
        cols="12">
        <v-row class="head-a4">
          <v-col cols="7">
            <div class="head-title">
              <slot name="title" />
            </div>
          </v-col>
          <v-col
            v-if="isPending || isApproved"
            cols="5"
            class="text-right">
            <p
              class="text-uppercase text-bold text-h6 mb-0"
              :class="getStatusColor(status)">
              {{ status }}
            </p>
          </v-col>
          <v-col cols="auto">
            <p>
              <span class="text-bold">จำนวนสินค้า</span> : <span>{{ count }}</span>
              /
              <span>{{ getNumber('amount') }}</span>
            </p>
            <p>
              <span class="text-bold">เลขที่</span> : {{ noteId || '-' }}
            </p>
            <p v-if="isApproved">
              <span class="text-bold">อนุมัติโดย</span> : {{ updateBy }}
            </p>
            <p v-if="isApproved">
              <span class="text-bold">วันที่อนุมัติ</span> : {{ updateAt }}
            </p>
            <p v-if="purchaseOrderId">
              <span class="text-bold">เลขที่ใบสั่งซื้อ</span> : {{ purchaseOrderId || '-' }}
            </p>
            <p v-if="assemblyOrderId">
              <span class="text-bold">เลขที่ใบสั่งผลิต</span> : {{ assemblyOrderId || '-' }}
            </p>
            <div class="d-flex align-center">
              <span class="text-bold">เหตุผล</span> :
              <v-select
                v-if="isDraft"
                class="warehouse-selector ml-4"
                :value="reason"
                :items="getReason"
                item-text="text"
                item-value="value"
                :menu-props="{ offsetY: true }"
                placeholder="เลือกเหตุผล"
                hide-details
                dense
                :disabled="['PURCHASE_ORDER', 'ASSEMBLY_ORDER'].includes(reason)"
                @input="updateReason($event)" />
              <span
                v-else
                class="ml-1">
                {{ reason }}
              </span>
            </div>
            <div
              v-if="reason === 'STOCK_COUNT' && noteType === 'deliveryNote'"
              class="d-flex align-center">
              <span class="text-bold">เอกสาร Ref.</span> :
              <v-text-field
                v-if="isDraft"
                placeholder="เลขที่เอกสารจาก BC"
                :value="bcRef"
                required
                class="ml-4"
                dense
                @input="updateBCRef($event)" />
              <span
                v-else
                class="ml-1">
                {{ bcRef }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <div class="">
              <span class="text-bold">คลังสินค้า</span> : {{ warehouse.name }}

              <v-icon
                v-if="noteType === 'withdrawNote' && haveDestination"
                color="black"
                small
                class="mx-2">
                mdi-arrow-right
              </v-icon>

              <v-icon
                v-else-if="refWarehouse && noteType === 'deliveryNote'"
                color="black"
                small
                class="mx-2">
                mdi-arrow-left
              </v-icon>

              <WarehouseSelect
                v-if="isDraft && noteType === 'withdrawNote' && haveDestination"
                v-model="computedTransferWarehouseId"
                :items="warehouses"
                item-text="name"
                item-value="id"
                :rules="textBoxRules"
                :menu-props="{ offsetY: true }"
                label=""
                placeholder="เลือกสาขา" />

              <span v-else-if="refWarehouse && refWarehouse.name">
                {{ refWarehouse.name }}
                ( <a @click="goToRef()">{{ noteRef.code }} </a> <span
                  class="text-uppercase text-bold"
                  :class="getStatusColor(noteRef.status)">{{ noteRef.status }}</span> )
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-2 mb-4">
      <v-col cols="12">
        <h2>รายการสินค้า</h2>
      </v-col>
      <v-col
        v-if="products.length === 0"
        cols="12"
        class="note-item-blank"
        justify="center"
        align="center">
        <v-col
          cols="auto"
          class="col-icon">
          <v-icon
            x-large
            class="icon-xxx-large">
            mdi-clipboard-list-outline
          </v-icon>
        </v-col>
        <v-col :cols="12">
          <v-divider />
        </v-col>
      </v-col>
      <v-col>
        <v-row
          v-for="(product, productIndex) in products"
          :key="product.id"
          class="note-item elevation-3 mb-2 rounded">
          <v-col
            :cols="2"
            class="note-item-image">
            <gw-product-image
              :src="product.photoUrl"
              custom-svg="auto"
              img />
          </v-col>
          <v-col
            :cols="10">
            <div>{{ product.name }}</div>
            <div class="product-model">
              {{ product.model }}
            </div>
            <v-btn
              v-if="isDraft"
              class="buttun-remove-product"
              color="error"
              small
              @click="removeProduct(productIndex)">
              <gw-icon-hover
                class="remove-img"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                color="#fff"
                small />
            </v-btn>
          </v-col>
          <v-col
            :cols="12">
            <v-simple-table
              class="sku-list"
              dense>
              <thead>
                <tr>
                  <th
                    v-for="(head, headIndex) in headTable"
                    :key="`head-${headIndex}`"
                    :class="{ 'text-right': head === 'Received' }"
                    class="capitalize">
                    {{ head }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sku, skuIndex) in product.skus"
                  :key="`sku-${skuIndex}`"
                  :class="{ 'tr-error': checkTrError(sku) }">
                  <td>
                    <span>
                      {{ sku.code || '-' }}
                    </span>
                    <p class="mb-0">
                      {{ sku.color || '-' }} - {{ sku.size || '-' }}
                    </p>
                  </td>
                  <td
                    v-if="isDraft && reason !== 'UNDO_RECEIVED'"
                    class="text-right">
                    <span>
                      {{ sku.receivedQty | showNumberFormat() }} / {{ sku.poQty | showNumberFormat() }}
                    </span>
                  </td>
                  <td
                    v-if="isDraft && reason !== 'UNDO_RECEIVED'"
                    width="100">
                    <v-text-field
                      v-model.number="sku.amount"
                      :rules="[...inputRules(sku.id, sku.amount), isOverAmount(sku)]"
                      class="note-count-input"
                      type="number"
                      outlined
                      solo
                      dense
                      :min="0"
                      @keypress="$keypressNumberNoDecimal($event)" />
                  </td>
                  <td
                    v-else
                    class="text-center"
                    width="75">
                    <span>{{ sku.amount }}</span>
                  </td>
                  <td
                    v-if="isDraft"
                    class="text-right"
                    width="75">
                    <v-btn
                      class="buttun-remove-sku"
                      color="grey lighten-4"
                      icon
                      x-small
                      @click="removeSku(productIndex, skuIndex)">
                      <gw-icon-hover
                        class="remove-img"
                        icon-name="mdi-delete"
                        icon-hover="mdi-delete-empty"
                        x-small />
                    </v-btn>
                  </td>
                  <td
                    v-else-if="isPending && reason !== 'UNDO_RECEIVED'">
                    <v-text-field
                      v-model.number="sku.count"
                      class="note-count-input"
                      style="width: 100px !important;"
                      type="number"
                      :rules="[ ...inputRules(sku.id, sku.amount), isOverCount(sku) ]"
                      outlined
                      solo
                      dense
                      :min="0"
                      @keypress="$keypressNumberNoDecimal($event)" />
                  </td>
                  <td
                    v-else
                    class="text-center"
                    width="65">
                    <span>
                      {{ sku.count }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-2 mb-4">
      <v-col :cols="12">
        <p class="caption mb-1">
          Note
        </p>
        <v-textarea
          :value="note"
          :disabled="!isDraft"
          :height="150"
          hide-details
          outlined
          solo
          dense
          @input="updateNote($event)" />
      </v-col>
    </v-row>
    <v-row
      v-if="isApproved && !isMobile"
      class="row-sign-name mt-15">
      <v-col :cols="6">
        <div class="sign-name">
          <p class="sign-name-title">
            ผู้นำสินค้าเข้า
          </p>
          <p>ลงชื่อ .....................................................</p>
          <p>
            <span>
              ( .................................................. )
            </span>
          </p>
          <p>วันที่ ............./............./.............</p>
        </div>
      </v-col>
      <v-col :cols="6">
        <div class="sign-name">
          <p class="sign-name-title">
            ผู้ดูแลสต็อกสินค้า
          </p>
          <p>ลงชื่อ .....................................................</p>
          <p>
            <span>
              ( .................................................. )
            </span>
          </p>
          <p>วันที่ ............./............./.............</p>
          <p> พิมพ์ {{ printAt }}</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getRole } from '@/assets/js/Authentication'
import WarehouseSelect from '@/components/WarehouseSelect.vue'

export default {
  components: {
    WarehouseSelect
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    qr: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: 'draft'
    },
    reason: {
      type: String,
      default: 'TRANSFER'
    },
    bcRef: {
      type: String,
      default: ''
    },
    warehouse: {
      type: Object,
      default: () => ({
        id: 0,
        name: 'Main',
        code: 'Main'
      })
    },
    noteId: {
      type: String,
      default: '-'
    },
    createBy: {
      type: String,
      default: '-'
    },
    updateBy: {
      type: String,
      default: '-'
    },
    printTime: {
      type: String,
      default: '-'
    },
    updateTime: {
      type: String,
      default: '-'
    },
    note: {
      type: String,
      default: ''
    },
    transferWarehouseId: {
      type: Number,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    noteRef: {
      type: Object,
      default: () => ({
        code: '',
        warehouseId: null,
        id: null
      })
    },
    stock: {
      type: Array,
      default: () => []
    },
    warehouseItems: {
      type: Array,
      default: () => []
    },
    noteType: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      reasons: [
        {
          text: 'TRANSFER',
          value: 'TRANSFER'
        },
        {
          text: 'MERCHANDISE',
          value: 'MER'
        },
        {
          text: 'STYLIST',
          value: 'STYLIST'
        },
        {
          text: 'REPAIR',
          value: 'REPAIR'
        },
        {
          text: 'PURCHASE ORDER',
          value: 'PURCHASE_ORDER'
        },
        {
          text: 'ASSEMBLY ORDER',
          value: 'ASSEMBLY_ORDER'
        },
        {
          text: 'DEFECT RETURN',
          value: 'DEF_RE'
        },
        {
          text: 'DEFECT NOT RETURN',
          value: 'DEF_NOT_RE'
        },
        {
          text: 'UNDO RECEIVED',
          value: 'UNDO_RECEIVED'
        },
        {
          text: 'SAMPLE',
          value: 'SAMPLE'
        }
      ],
      textBoxRules: [
        (v) => !!v || 'Field is require.'
      ]
    }
  },
  computed: {
    refWarehouse () {
      const warehouse = this.warehouseItems.find((r) => r.id === this.computedTransferWarehouseId)
      return warehouse ? {
        id: warehouse.id,
        name: warehouse.name,
        code: warehouse.code
      } : null
    },
    printAt () {
      return this.getTime(this.printTime)
    },
    updateAt () {
      return this.getTime(this.updateTime)
    },
    isDraft () {
      return this.status === 'draft'
    },
    isPending () {
      return this.status === 'pending'
    },
    isCanceled () {
      return this.status === 'canceled'
    },
    isApproved () {
      return this.status === 'approved'
    },
    isBlank () {
      return this.status === ''
    },
    isStock () {
      return (this.isPending || this.isDraft) && this.stock && this.stock.length > 0
    },
    getReason () {
      const mainWarehouses = ['GW-WHS', 'MM-WHS']
      const sampleWarehouses = ['GW-SAMPLE', 'MM-SAMPLE']
      let reasons = []

      if (this.noteType === 'withdrawNote' && this.deliveryNoteId) {
        return this.reasons.filter((v) => v.value === 'UNDO_RECEIVED')
      }

      if (this.noteType === 'withdrawNote' && mainWarehouses.includes(this.warehouse.code)) {
        reasons = this.reasons.filter((v) => !(['PURCHASE_ORDER', 'ASSEMBLY_ORDER', 'SAMPLE', 'UNDO_RECEIVED'].includes(v.value)))
      } else if (this.noteType === 'withdrawNote' && sampleWarehouses.includes(this.warehouse.code)) {
        reasons = this.reasons.filter((v) => !(['PURCHASE_ORDER', 'ASSEMBLY_ORDER', 'REPAIR', 'UNDO_RECEIVED'].includes(v.value)))
      } else if (this.noteType === 'withdrawNote') {
        reasons = this.reasons.filter((v) => !(['PURCHASE_ORDER', 'ASSEMBLY_ORDER', 'REPAIR', 'SAMPLE', 'UNDO_RECEIVED'].includes(v.value)))
      }

      const role = getRole()

      if (this.noteType === 'deliveryNote' && role === 'developer') {
        reasons.push({ text: 'STOCK_COUNT', value: 'STOCK_COUNT' })
      }

      if (this.noteType === 'withdrawNote' && (role === 'developer' || role === 'inventory_and_costing')) {
        reasons.push({ text: 'DEFECT', value: 'DEFECT' }, { text: 'STOCK_COUNT', value: 'STOCK_COUNT' })
      }

      if (this.purchaseOrderId) {
        const filtered = this.reasons.filter((v) => ['PURCHASE_ORDER', 'ASSEMBLY_ORDER'].includes(v.value))

        reasons.push(...filtered)
      }

      return reasons
    },
    headTable () {
      let headTable = [
        'code',
        'option',
        'amount',
        'count'
      ]

      if (this.isDraft && this.reason !== 'UNDO_RECEIVED') {
        headTable = [
          'Code',
          'Received',
          'Option',
          'Amount',
          ''
        ]
      } else if (this.isDraft && this.reason === 'UNDO_RECEIVED') {
        headTable = [
          'code',
          'option',
          'amount',
          ''
        ]
      } else if (this.isPending && this.reason !== 'UNDO_RECEIVED' && this.noteType === 'deliveryNote') {
        headTable = [
          'code',
          'option',
          'amount',
          'WN/PO',
          'count'
        ]
      }

      if (this.isStock) {
        headTable.splice(headTable.length - 2, 0, 'stock')
      }
      if (this.isMobile) {
        headTable = headTable.filter((ht) => !['WN/PO', 'option', 'Option', 'stock'].includes(ht))
      }
      return headTable
    },
    purchaseOrderId () {
      return this.$route.query.poNo || ''
    },
    assemblyOrderId () {
      return this.$route.query.aoNo || ''
    },
    deliveryNoteId () {
      return this.$route.query.dnNo || ''
    },
    warehouses () {
      const mainWarehouses = ['GW-WHS', 'MM-WHS']
      const mappedReasons = {
        defect: {
          reasons: ['DEF_RE'],
          warehouses: ['GW-DEF', 'MM-DEF']
        },
        defectNoReturn: {
          reasons: ['DEF_NOT_RE'],
          warehouses: ['GW-WHS', 'MM-WHS']
        },
        repair: {
          reasons: ['REPAIR'],
          warehouses: ['GW-RPR', 'MM-RPR']
        },
        sample: {
          reasons: ['SAMPLE'],
          warehouses: ['GW-SAMPLE', 'MM-SAMPLE']
        }
      }

      if (mappedReasons.defect.reasons.includes(this.reason) && mainWarehouses.includes(this.warehouse.code)) {
        return [
          {
            id: null,
            name: 'เลือกสาขา'
          },
          ...this.warehouseItems.filter((v) => mappedReasons.defect.warehouses.includes(v.code))
        ]
      }

      if (mappedReasons.defect.reasons.includes(this.reason)) {
        return [
          {
            id: null,
            name: 'เลือกสาขา'
          },
          ...this.warehouseItems.filter((v) => mainWarehouses.includes(v.code))
        ]
      }

      if (mappedReasons.defectNoReturn.reasons.includes(this.reason)) {
        return [
          {
            id: null,
            name: 'เลือกสาขา'
          },
          ...this.warehouseItems.filter((v) => mappedReasons.defectNoReturn.warehouses.includes(v.code))
        ]
      }

      if (mappedReasons.repair.reasons.includes(this.reason) && mainWarehouses.includes(this.warehouse.code)) {
        return [
          {
            id: null,
            name: 'เลือกสาขา'
          },
          ...this.warehouseItems.filter((v) => mappedReasons.repair.warehouses.includes(v.code))
        ]
      }

      return [
        {
          id: null,
          name: 'เลือกสาขา'
        },
        ...this.warehouseItems
      ]
    },
    haveDestination () {
      const notHaveDestinationReason = [
        'UNDO_RECEIVED',
        'SAMPLE',
        'DEFECT',
        'STOCK_COUNT'
      ]
      return !notHaveDestinationReason.includes(this.reason)
    },
    computedTransferWarehouseId: {
      get () {
        return this.transferWarehouseId
      },
      set (val) {
        this.$emit('update:transfer-warehouse-id', val)
      }
    }
  },
  methods: {
    getStatusColor (status) {
      switch (status) {
        case 'pending': return 'status-text-pending'
        case 'approved': return 'status-text-approved'
        case 'canceled': return 'status-text-canceled'
        case 'draft': return 'status-text-draft'
        default: return ''
      }
    },
    goToRef () {
      let routeData = null
      if (this.noteType === 'deliveryNote') {
        routeData = this.$router.resolve({
          name: 'WithdrawNote',
          query: {
            id: this.noteRef.id,
            warehouse: this.noteRef.warehouseId
          }
        })
      } else {
        routeData = this.$router.resolve({
          name: 'DeliveryNote',
          query: {
            id: this.noteRef.id,
            warehouse: this.noteRef.warehouseId
          }
        })
      }
      window.open(routeData.href, '_blank')
    },
    removeProduct (index) {
      this.$emit('remove-product', index)
    },
    removeSku (productIndex, skuIndex) {
      this.$emit('remove-sku', {
        productIndex,
        skuIndex
      })
    },
    checkTrError (sku) {
      if (this.isDraft) {
        return false
      }
      return sku.amount !== sku.count
    },
    updateReason (val) {
      this.$emit('update:reason', val)
    },
    updateBCRef (val) {
      this.$emit('update:bcRef', `${val}`.trim())
    },
    updateNote (text) {
      this.$emit('update:note', text)
    },
    getNumber (key) {
      let number = 0
      for (let i = 0; i < this.products.length; i++) {
        const product = this.products[i]
        for (let j = 0; j < product.skus.length; j++) {
          const sku = product.skus[j]
          number += sku[key] ? parseInt(sku[key]) : 0
        }
      }
      return number || 0
    },
    getTime (time) {
      if (time && time !== '-') {
        return this.$dayjs(time).locale('th').format('LLL')
      }
      return '-'
    },
    getStock (id, amount) {
      if (this.isStock) {
        const sku = this.stock.find((r) => r.id === id)
        const onReservedQty = sku.stock && sku.stock.onReservedQty ? sku.stock.onReservedQty : 0
        const onHandQty = sku.stock && sku.stock.onHandQty ? sku.stock.onHandQty : 0
        const calcStock = this.isPending ? onReservedQty - amount : onReservedQty
        return { onHandQty, calcStock }
      }
      return { onHandQty: 0, calcStock: 0 }
    },
    mapStock (id, amount) {
      if (this.isStock) {
        const sku = this.stock.find((r) => r.id === id)
        const onReservedQty = sku.stock && sku.stock.onReservedQty ? sku.stock.onReservedQty : 0
        const onHandQty = sku.stock && sku.stock.onHandQty ? sku.stock.onHandQty : 0
        const calcStock = this.isPending ? onReservedQty - amount : onReservedQty
        return `${onHandQty} (${calcStock})`
      }
      return ''
    },
    inputRules (id, amount) {
      if (this.isStock) {
        const sku = this.stock.find((r) => r.id === id)
        const onReservedQty = sku.stock && sku.stock.onReservedQty ? sku.stock.onReservedQty : 0
        const onHandQty = sku.stock && sku.stock.onHandQty ? sku.stock.onHandQty : 0
        const reserved = this.isPending ? onReservedQty - amount : onReservedQty
        const calcStock = onHandQty - reserved
        if (this.isPending) {
          return [
            (v) => (v >= 1 || 'ค่าต้องมากกว่า 1'),
            (v) => (v <= calcStock || 'Stock ไม่พอ')
          ]
        }
        return [
          (v) => (v >= 1 || 'ค่าต้องมากกว่า 1'),
          (v) => (v <= calcStock || 'Stock ไม่พอ')
        ]
      }
      return []
    },
    isOverAmount (sku) {
      if (this.reason === 'ASSEMBLY_ORDER' && this.isDraft) {
        return true
      }

      return sku.amount > sku.maxAmount ? 'จำนวนเกิน WN/PO' : true
    },
    isOverCount (sku) {
      return sku.count > sku.maxAmount ? 'จำนวนเกิน WN/PO' : true
    }
  }
}
</script>

<style lang="scss" scoped>
.status-text-pending {
  color: #EF5350;
}
.status-text-approved {
  color: #40a145;
}
.status-text-canceled {
  color: #363636;
}
.status-text-draft {
  color: #cacaca;
}
div[size="A4"] {
  display: block;
  width: 210mm;
  min-height: 297mm;
  margin: 10px auto;
  padding: calc(12.7mm - 24px);
  background-color: #ffffff;
  .col {
    position: relative;
  }
  .buttun-remove-product {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
}
div[size="MOBILE"] {
  display: block;
  width: 100%;
  min-height: 297mm;
  margin: 10px auto;
  padding: calc(12.7mm - 24px);
  background-color: #ffffff;
  .col {
    position: relative;
  }
  .buttun-remove-product {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
}
.head-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    font-size: 26px;
  }
}
.head-note-detail p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  line-height: 1.25rem;
}
.head-item-list h4 {
  padding: 5px 0 5px 5px;
  border: 0;
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
}
.note-item-blank {
  > .col-icon {
    min-height: 250px;
    display: flex;
  }
  i.v-icon.icon-xxx-large {
    font-size: 80px !important;
  }
}
.sign-name {
  text-align: center;
  p {
    font-size: 12px;
  }
  p.sign-name-title {
    font-size: 14px;
    font-weight: 600;
  }
}

</style>

<style lang="scss">
.v-input.note-count-input.v-text-field {
  width: 120px;
  > .v-input__control {
    // width: 100px;
    min-height: unset;
    > .v-input__slot {
      min-height: unset;
      font-size: 14px;
      // width: 100px;
      height: 25px;
      padding: 0 5px;
      box-shadow: unset;
      input {
        padding: 0;
      }
    }
  }
}
td, th {
  padding: 4px !important;
}
div.v-text-field__details {
  margin-bottom: 0px !important;
}
tr {
  vertical-align: top !important;
}
.v-data-table.sku-list tbody tr td {
  border-bottom: 5px solid #fff !important;
  height: 42px !important;
}
tr.tr-error {
  background-color: #f2191726!important;
}
tr.tr-error:hover {
  background-color: #f2191716 !important;
}
@media print {
  tbody tr.tr-error {
    background-color: transparent !important;
  }
  .note-item-image .product-image {
    padding-left: 1px;
  }
  .note-text .v-input.v-textarea {
    .v-input__slot {
      box-shadow: unset !important;
      fieldset {
        background-color: transparent;
        border: 0;
      }
      textarea {
        resize: none;
      }
    }
  }
}
.product-model {
  font-size: 12px !important;
  color: #3c3c3c;
  margin-top: -5px;
}
span.sign-bracket {
  display: block;
  margin-left: 25px;
}
.warehouse-selector {
  width: 250px;
  font-size: 14px;
}
.warehouse-section {
  min-height: 30px;
}
</style>
